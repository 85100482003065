import { FileInput } from "./FileInput"

import { gql } from "@apollo/client"
import { useS3Upload } from "lib/hooks/useS3"
import { useFieldArray } from "react-hook-form"
import * as React from "react"
import { useForm } from "lib/hooks/useForm"
import { UploadSchema } from "lib/yup"
import * as yup from "yup"
import { UPLOAD_PATHS } from "lib/uploadPaths"
import { Form } from "./Form"
import { Input } from "./Input"
import { Select } from "./Select"
import {
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react"
import { FiPlus, FiX } from "react-icons/fi"
import { FormError } from "./FormError"
import { ButtonGroup } from "./ButtonGroup"
import { useSubmitPinkPopFormMutation } from "lib/graphql"
import { regions } from "lib/static/filters"

const _ = gql`
  mutation SubmitPinkPopForm($data: PinkPopFormInput!) {
    submitPinkPopForm(data: $data)
  }
`

const FormSchema = yup.object().shape({
  firstName: yup.string().required("Voornaam is verplicht"),
  lastName: yup.string().required("Achternaam is verplicht"),
  email: yup.string().email("Ongeldig e-mailadres").required("E-mailadres is verplicht"),
  phone: yup.string().required("Telefoonnummer is verplicht"),
  age: yup.number().required("Leeftijd is verplicht"),
  region: yup.string().required("Provincie is verplicht"),
  location: yup.string().required("Woonplaats is verplicht"),
  coverBand: yup.string().required("Artiest is verplicht"),
  coverSong: yup.string().required("Titel is verplicht"),
  video: UploadSchema,
  groupMembers: yup
    .array()
    .of(
      yup.object().shape({
        firstName: yup.string().required("Voornaam is verplicht"),
        lastName: yup.string().required("Achternaam is verplicht"),
        age: yup.number().required("Leeftijd is verplicht"),
      }),
    )
    .required("Groepsleden zijn verplicht"),
})

export function PinkPopForm() {
  const [isVideoUploading, setIsVideoUploading] = React.useState(false)
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false)

  const [submitPinkPopForm, { loading: submitLoading }] = useSubmitPinkPopFormMutation()

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    age: "",
    region: "",
    location: "",
    groupMembers: [{ firstName: "", lastName: "", age: "" }],
    coverBand: "",
    coverSong: "",
    video: "",
  }
  const form = useForm({ defaultValues, schema: FormSchema })
  const [upload] = useS3Upload({ path: UPLOAD_PATHS.pinkPopPageForm() })
  const { fields, append, remove } = useFieldArray({ control: form.control, name: "groupMembers" })

  const handleSubmit = async (data: yup.InferType<typeof FormSchema>) => {
    if (!data.video) {
      form.setError("video", { message: "Video is required" })
      return
    }
    let file = data.video
    if (data.video && typeof data.video !== "string") {
      setIsVideoUploading(true)
      const uploadedFile = await upload(data.video)
      if (!uploadedFile) return
      file = uploadedFile.fileKey
      setIsVideoUploading(false)
    }
    return form.handler(() => submitPinkPopForm({ variables: { data: { ...data, video: file } } }), {
      onSuccess: (_, toast) => {
        toast({ description: "Formulier verzonden" })
        form.reset() // manually resetting as shouldResetAfterSubmit is not working
      },
    })
  }
  return (
    <Stack py={8} spacing={4}>
      <Heading as="h2">Cover Insturen</Heading>
      <Form {...form} onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <Stack>
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              <Input name="firstName" label="Voornaam" isRequired />
              <Input name="lastName" label="Achternaam" isRequired />
            </SimpleGrid>

            <Input name="email" label="E-mailadres" isRequired />

            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              <Input name="phone" label="Telefoonnummer" isRequired />
              <Input name="age" label="Leeftijd" type="number" isRequired />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              <Select name="region" label="Provincie" options={regions} isRequired />
              <Input name="location" label="Woonplaats" isRequired />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4}>
              <Input name="coverBand" label="Artiest of band van wie jij een cover hebt gemaakt" isRequired />
              <Input name="coverSong" label="Titel van het gecoverde lied" isRequired />
            </SimpleGrid>
            <FileInput
              name="video"
              label="Upload je video, max 500MB (.mp4, .mov, .avi)"
              isRequired
              dropzoneOptions={{
                maxSize: 1024 * 1024 * 500, // 500MB
                accept: {
                  "video/mp4": [".mp4"],
                  "video/quicktime": [".mov"],
                  "video/x-msvideo": [".avi"],
                },
              }}
            />
            <Stack p={3} border="1px solid" borderColor="gray.700" borderRadius="md" spacing={4}>
              {fields.map((field, index) => (
                <Stack key={field.id}>
                  <HStack w="100%" justify="space-between">
                    <Text fontWeight="semibold">Groepslid {index + 1}</Text>
                    <IconButton
                      aria-label="Groepslid verwijderen"
                      icon={<FiX size={22} />}
                      variant="ghost"
                      size="sm"
                      colorScheme="red"
                      onClick={() => remove(index)}
                      isDisabled={fields.length === 1}
                      mb={1}
                    />
                  </HStack>
                  <SimpleGrid columns={{ base: 1, sm: 3 }} spacing={4} w="100%">
                    <Input name={`groupMembers.${index}.firstName`} label="Voornaam" isRequired w="100%" />
                    <Input name={`groupMembers.${index}.lastName`} label="Achternaam" isRequired w="100%" />
                    <Input
                      name={`groupMembers.${index}.age`}
                      label="Leeftijd"
                      type="number"
                      isRequired
                      w="100%"
                    />
                  </SimpleGrid>
                </Stack>
              ))}
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => append({ firstName: "", lastName: "", age: "" })}
                leftIcon={<FiPlus size={20} />}
              >
                Groepsleden toevoegen
              </Button>
            </Stack>
          </Stack>
          <HStack spacing={4}>
            <Checkbox
              id="terms"
              onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
              colorScheme="yellow"
              size="lg"
            />
            <FormLabel mb={0} fontSize="sm" htmlFor="terms" cursor="pointer" opacity={0.8}>
              Ik ga akkoord met de spelregels en mijn cover mag gepubliceerd worden op YouTube, Instagram en
              TikTok en mag bewaard worden in de archieven van Pinkpop en Kunstbende
            </FormLabel>
          </HStack>
          <FormError />
          <ButtonGroup>
            <Button
              type="submit"
              isDisabled={!hasAcceptedTerms}
              isLoading={submitLoading || isVideoUploading}
              colorScheme="yellow"
              loadingText={isVideoUploading ? "Video uploaden..." : "Indienen..."}
            >
              Indienen
            </Button>
          </ButtonGroup>
        </Stack>
      </Form>
    </Stack>
  )
}
