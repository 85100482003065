import * as React from "react"
import { gql } from "@apollo/client"
import { Center, Spinner, Stack } from "@chakra-ui/react"
import Head from "next/head"
import { useRouter } from "next/router"

import { useGetPageQuery } from "lib/graphql"
import { Layout } from "components/Layout"
import { Limiter } from "components/Limiter"
import { SanitizedContent } from "components/SanitizedContent"
import { PinkPopForm } from "components/PinkPopForm"

const _ = gql`
  fragment PageDetail on Page {
    id
    name
    content
  }
  query GetPage($slug: String!) {
    page(slug: $slug) {
      ...PageDetail
    }
  }
`

export default function PageDetail() {
  const router = useRouter()
  const slug = router.query.slug as string

  const { data, loading } = useGetPageQuery({ variables: { slug }, skip: !!!slug })
  const page = data?.page

  const isPinkPop = slug === "pinkpop-cover-contest"
  // const isPinkPop = false

  return loading ? (
    <Center h="100vh" w="100vw">
      <Spinner />
    </Center>
  ) : !page ? (
    <Center h="100vh" w="100vw">
      Pagina niet gevonden
    </Center>
  ) : (
    <>
      <Head>
        <title>Kunstbende - {page.name}</title>
      </Head>
      <Limiter my={12}>
        <Center w="100%">
          <Stack spacing={8} maxWidth="800px" align="left">
            <SanitizedContent content={page.content} />
            {isPinkPop && <PinkPopForm />}
          </Stack>
        </Center>
      </Limiter>
    </>
  )
}

PageDetail.getLayout = (page: React.ReactElement) => <Layout>{page}</Layout>
